<template>
  <el-dialog
    :title="detailData && detailData.id ? '编辑' : '添加'"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    destroy-on-close
    width="40%"
  >
    <div>
      <el-form ref="dataForm" :model="dataForm" :rules="rules"
        ><el-form-item prop="serviceName">
          <TextInput
            v-model="dataForm.serviceName"
            :config="{
              type: 'input',
              required: true,
              label: '服务项目名称：',
              width: '100%',
            }"
          >
          </TextInput>
        </el-form-item>
        <el-form-item prop="serviceStandard">
          <TextInput
            v-model="dataForm.serviceStandard"
            :config="{
              type: 'textarea',
              required: true,
              label: '服务项目标准：',
              width: '100%',
            }"
          >
          </TextInput>
        </el-form-item>
        <el-form-item prop="serviceAmount">
          <TextInput
            v-model="dataForm.serviceAmount"
            :config="{
              type: 'input',
              required: true,
              label: '服务项目金额：',
              isNum: true,
              width: '100%',
            }"
          >
          </TextInput>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">关闭</el-button>
      <el-button @click="submitForm" type="primary">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import TextInput from "@/components/SimpleTable/TextInput.vue";
import { addServiceProject } from "@/api/safeDuty";
import _ from "lodash";
export default {
  name: "ServiceDictAdd",
  components: { TextInput },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
      default: () => {
        return null;
      },
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    show(n) {
      if (n) {
        this.dataForm = _.cloneDeep(this.detailData);
      }
      this.dialogVisible = n;
    },
    dialogVisible(n) {
      if (!n) {
        this.$emit("update:show", false);
      }
    },
    // detailData: {
    //   deep: true,
    //   handler(n) {
    //     if (n) {
    //       this.dataForm = _.cloneDeep(n);
    //     }
    //   },
    // },
  },
  data() {
    return {
      dialogVisible: false,
      dataForm: {
        id: "",
        serviceName: "",
        serviceStandard: "",
        serviceAmount: "",
      },
      rules: {
        serviceName: [
          {
            required: true,
            message: "服务项目名称",
            trigger: "blur",
          },
        ],
        serviceStandard: [
          {
            required: true,
            message: "服务项目标准",
            trigger: "blur",
          },
        ],
        serviceAmount: [
          {
            required: true,
            message: "服务项目金额",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    //清空
    clearForm() {
      let keys = Object.keys(this.dataForm);
      keys.forEach((key) => {
        if (this.dataForm[key] instanceof Array) {
          this.dataForm[key] = [];
        } else if (this.dataForm[key] instanceof Object) {
          Object.keys(this.dataForm[key]).forEach((key2) => {
            this.dataForm[key][key2] = "";
          });
        } else {
          this.dataForm[key] = "";
        }
      });
    },
    handleClose(done) {
      this.clearForm();
      done();
    },
    submitForm() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          addServiceProject(this.dataForm).then((res) => {
            this.$emit("submit");
            this.clearForm();
            this.dialogVisible = false;
            this.$message({
              message: "添加或编辑成功",
              type: "success",
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
