<template>
  <div>
    <SimpleTable ref="table" :tableProps="tableProps" :queryFun="queryFun">
      <template slot="left-r">
        <el-button
          @click="editItem({})"
          class="add-btn"
          icon="el-icon-circle-plus-outline"
          >添加</el-button
        >
      </template>
      <div slot="action" slot-scope="{ data }">
        <el-button type="text" @click="editItem(data.row)">编辑</el-button>
        <el-button
          @click="deletItem(data.row)"
          type="text"
          style="color: #f64a2d"
          >删除</el-button
        >
      </div>
    </SimpleTable>
    <ServiceDictAdd
      :show.sync="addShow"
      @submit="submitDict"
      :detailData="currentItem"
    ></ServiceDictAdd>
  </div>
</template>

<script>
import SimpleTable from "@/components/SimpleTable";
import ServiceDictAdd from "./components/ServiceDictAdd.vue";
import { getServiceDictList, deletServiceProject } from "@/api/safeDuty";
export default {
  components: { SimpleTable, ServiceDictAdd },
  data() {
    return {
      dictList: { ServiceProject: [] },
      addShow: false,
      tableProps: {
        isFuzzyQuery: false,
        fuzzyQueryKey: "fuzzySearch",
        currentPageKey: "current",
        currentSizeKey: "size",
        totalKey: "total",
        height: 600,
        searchConfigs: [
          {
            modelKey: "name",
            label: "服务项目",
            type: "input",
            placeholder: "请输入服务项目名称",
          },
          {
            label: "录入人",
            type: "input",
            modelKey: "userName",
          },
          {
            label: "录入时间",
            type: "daterange",
            daterangeKeys: ["createTimeStart", "createTimeEnd"],
            // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "createT",
          },
          {
            label: "更新时间",
            type: "daterange",
            daterangeKeys: ["updateTimeStart", "updateTimeEnd"],
            // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "updateT",
          },
        ],
        columnProps: [
          {
            prop: "",
            label: "序号",
            width: "55",
            type: "index",
            isShow: true,
          },
          {
            prop: "serviceName",
            label: "服务项目名称",
            width: "",
            type: "",
            isShow: true,
          },

          {
            prop: "createUserName",
            label: "录入人",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "createTime",
            label: "录入时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "updateTime",
            label: "更新时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "action",
            label: "操作",
            width: 100,
            type: "",
            slot: true,
            isShow: true,
          },
        ],
      },
      currentItem: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {},
    async queryFun(queryParams) {
      let res = await getServiceDictList(queryParams);
      return {
        localData: res.data.records || [],
        total: res.data.total,
      };
    },
    deletItem(item = {}) {
      this.$confirm("是否删除", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deletServiceProject([item.id]).then(() => {
          this.$refs.table.getData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    },
    editItem(item) {
      this.currentItem = item;
      this.addShow = true;
    },
    submitDict(data) {
      // this.init();
      this.$refs.table.getData();
      this.currentItem = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-btn {
  background: #00bc0d;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #00bc0d;
}
</style>
