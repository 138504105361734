var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":_vm.detailData && _vm.detailData.id ? '编辑' : '添加',"visible":_vm.dialogVisible,"before-close":_vm.handleClose,"close-on-click-modal":false,"destroy-on-close":"","width":"40%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',[_c('el-form',{ref:"dataForm",attrs:{"model":_vm.dataForm,"rules":_vm.rules}},[_c('el-form-item',{attrs:{"prop":"serviceName"}},[_c('TextInput',{attrs:{"config":{
            type: 'input',
            required: true,
            label: '服务项目名称：',
            width: '100%',
          }},model:{value:(_vm.dataForm.serviceName),callback:function ($$v) {_vm.$set(_vm.dataForm, "serviceName", $$v)},expression:"dataForm.serviceName"}})],1),_c('el-form-item',{attrs:{"prop":"serviceStandard"}},[_c('TextInput',{attrs:{"config":{
            type: 'textarea',
            required: true,
            label: '服务项目标准：',
            width: '100%',
          }},model:{value:(_vm.dataForm.serviceStandard),callback:function ($$v) {_vm.$set(_vm.dataForm, "serviceStandard", $$v)},expression:"dataForm.serviceStandard"}})],1),_c('el-form-item',{attrs:{"prop":"serviceAmount"}},[_c('TextInput',{attrs:{"config":{
            type: 'input',
            required: true,
            label: '服务项目金额：',
            isNum: true,
            width: '100%',
          }},model:{value:(_vm.dataForm.serviceAmount),callback:function ($$v) {_vm.$set(_vm.dataForm, "serviceAmount", $$v)},expression:"dataForm.serviceAmount"}})],1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("关闭")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("确 定")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }