import { render, staticRenderFns } from "./ServiceDictAdd.vue?vue&type=template&id=ee28b990&scoped=true"
import script from "./ServiceDictAdd.vue?vue&type=script&lang=js"
export * from "./ServiceDictAdd.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee28b990",
  null
  
)

export default component.exports